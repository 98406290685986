body {
  background-color: black;
}

.container {
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
}

@media (max-width: 600px) {
  .container {
    width: 100%;
  }
}

.grip-wrapper {
  position: relative;
  max-width: 600px;
  aspect-ratio: 9 / 16;
}

@media (max-width: 600px) {
  .grip-wrapper {
    width: 100%;
    max-width: 100%;
    aspect-ratio: none;
  }
}

.grip {
  height: 100vh;
}

@media (max-width: 600px) {
  .grip-wrapper {
    width: 100%;
  }
}

.overlay {
  position: absolute;
  top: 50px;
  right: 50px;
}

.popup {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(10px);
}

.popup-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 18px 34px rgba(5, 16, 55, 0.15);
  border-radius: 12px;
  padding: 30px 20px;
  box-sizing: border-box;
  text-align: center;
}

.popup-wrapper p {
  font-size: 18px;
  font-weight: 600;
}
.popup.open {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup .login-btn {
  width: 90px;
  height: 40px;
  background: #6263d5;
  border-radius: 6px;
  color: #ffffff;
  outline: none;
  border: none;
  font-weight: bold;
  font-size: 15px;
}
